import React from "react";
import Layout from "../components/layout";

export default () => (
  <Layout title="Paul Love - CV and portfolio">
    <header>
      <h1>Paul Love</h1>
      <h2>Front-end technical lead and developer</h2>
    </header>
    <nav>
      <div className="contact">
        <h2 className="context">Contact</h2>
        <p>
          <span className="print-only">Email: </span>
          <a href="mailto:paul@anglepoised.com">paul@anglepoised.com</a>{" "}
          <span className="no-print">
            {" "}
            / <a href="https://github.com/anglepoised">GitHub</a> /{" "}
            <a href="http://www.linkedin.com/in/paullove">LinkedIn</a> /{" "}
            <a href="http://notes.anglepoised.com/">Blog</a> /{" "}
            <a href="http://anglepoised.com/">More&hellip;</a>
          </span>
        </p>
      </div>
    </nav>
    <main>
      <section>
        <div className="section introduction">
          <h2>Overview</h2>
          <p>
            I have over 15 years experience leading the successful delivery of
            solid front-end solutions for complex e-commerce, CMS and
            application builds, including Waitrose, Boots, Debenhams, The
            British Heart Foundation, The Home Office and Royal Shakespeare
            Company.
          </p>
          <p>
            I sit very firmly on the visual and user-centric end of the
            front-end spectrum, rather than the JavaScript side.
          </p>
          <p>
            I'm currently focusing on projects that involve some or all of the
            following: component-led CSS, living style guides, SSGs, PostCSS,
            SUIT CSS, Grunt, Gulp, npm scripts and web performance.
          </p>
        </div>
      </section>
      <section>
        <div className="section featured-projects">
          <h2>Featured projects</h2>
          <h3>
            <a href="http://scoota.com/">Scoota</a>{" "}
            <span className="date">(2014 - 2015)</span>
          </h3>
          <p>
            I helped Scoota develop{" "}
            <a href="https://github.com/anglepoised/rbx_ui_components/">
              custom UI components
            </a>{" "}
            for use across a complex, microservices-drive front-end.
          </p>
          <h3>
            <a href="http://www.waitrose.com/">Waitrose</a>{" "}
            <span className="date">(Grand Union, 2012 - 2013)</span>
          </h3>
          <p>
            Starting with exploratory work to establish the most effective way
            for multiple client and agency teams to develop code
            collaboratively, I led the initial 7 months of preparation and
            development on the strategically-focussed rebuild of the
            Waitrose.com front-end, including:
          </p>
          <ul>
            <li>
              liaising with client teams to determine technical objectives and
              constraints
            </li>
            <li>
              defining the scope of work and project plans alongside production
              staff
            </li>
            <li>writing comprehensive development standards</li>
            <li>hiring and managing a team of developers</li>
            <li>
              defining a development approach using Vagrant, Fabric, Flowdock,
              and GitHub, alongside a custom static site generator built with
              Sinatra, Haml and Sass
            </li>
            <li>
              working with UX and visual design teams to ensure new features
              were logical and achievable.
            </li>
          </ul>
          <h3>
            <a href="http://wearesuburb.com/">We Are Suburb</a>{" "}
            <span className="date">(Suburb, 2010)</span>
          </h3>
          <p>
            As Director of Operations, I co-ordinated the first phase of an
            agency reboot. During this nine month contract, I:
          </p>
          <ul>
            <li>helped redefine the agency's business model</li>
            <li>oversaw the creation of a new visual identity and website</li>
            <li>
              facilitated the company's smooth transition to larger premises
            </li>
            <li>interviewed and recruited staff for key positions</li>
            <li>created procedures for common business operations.</li>
          </ul>
          <h3>
            <a href="http://webarchive.nationalarchives.gov.uk/20060315075551/http://www.homeoffice.gov.uk/">
              The Home Office
            </a>{" "}
            <span className="date">(TMG, 2006)</span>
          </h3>
          <p>
            I built templates for the main Home Office website and all public
            and policy portals over 9 months, working alongside one other
            developer and a small UX and design team.
          </p>
          <p>
            The site won an{" "}
            <a href="https://www.interactivemediaawards.com/winners/certificate.asp?param=42774&amp;cat=1">
              Interactive Media Award
            </a>{" "}
            (Best In Class, Government), achieved RNIB "See it Right"
            accreditation and was judged the clear winner in Porter Research's
            2006 "Key Government Websites" report.
          </p>
        </div>
      </section>
      <section>
        <div className="section other-projects">
          <h2>Further projects</h2>
          <ul>
            <li>
              Coca-Cola Enterprises style guide{" "}
              <span className="date">(Head London, 2016)</span>
            </li>
            <li>
              Fitness First{" "}
              <span className="date">(Mentally Friendly, 2014)</span>
            </li>
            <li>
              FSCS Protected <span className="date">(Lost Property, 2014)</span>
            </li>
            <li>
              British Heart Foundation{" "}
              <span className="date">(Precedent, 2013)</span>
            </li>
            <li>
              ACI Worldwide <span className="date">(Precedent, 2013)</span>
            </li>
            <li>
              Reckitt Benckiser: Global Share{" "}
              <span className="date">(Wildfish, 2013)</span>
            </li>
            <li>
              Snapp <span className="date">(Wildfish, 2012)</span>
            </li>
            <li>
              Patek Philippe: Institutional{" "}
              <span className="date">(Leagas Delaney, 2012)</span>
            </li>
            <li>
              CIPFA <span className="date">(Precedent, 2011)</span>
            </li>
            <li>
              Onzo <span className="date">(Wildfish, 2011)</span>
            </li>
            <li>
              The Shard: London Bridge Quarter and The Place{" "}
              <span className="date">(Sectorlight, 2010)</span>
            </li>
            <li>
              Royal Shakespeare Company{" "}
              <span className="date">(Harvest Digital, 2009)</span>
            </li>
            <li>
              Best Buy Europe <span className="date">(Grand Union, 2009)</span>
            </li>
            <li>
              Fly Dubai <span className="date">(Grand Union, 2009)</span>
            </li>
            <li>
              Boots <span className="date">(Grand Union, 2008)</span>
            </li>
            <li>
              Debenhams <span className="date">(Grand Union, 2008)</span>
            </li>
            <li>
              Vodafone <span className="date">(Dare, 2008)</span>
            </li>
            <li>
              Abbey Road Studios <span className="date">(Suburb, 2008)</span>
            </li>
            <li>
              Electoral Commission{" "}
              <span className="date">(Grand Union, 2007)</span>
            </li>
            <li>
              Energy Saving Trust{" "}
              <span className="date">(Grand Union, 2007)</span>
            </li>
            <li>
              Digital UK <span className="date">(Grand Union, 2006)</span>
            </li>
            <li>
              HSBC: The Property Panel{" "}
              <span className="date">(Hubbub, 2006)</span>
            </li>
            <li>
              Transport for London: Le Grand Depart{" "}
              <span className="date">(CMWi, 2006)</span>
            </li>
            <li>
              Auto Trader <span className="date">(Harvest Digital, 2006)</span>
            </li>
            <li>
              Be Broadband{" "}
              <span className="date">(Kane + Associates, 2005)</span>
            </li>
            <li>
              Lulu Guinness <span className="date">(Suburb, 2004)</span>
            </li>
            <li>
              Homechoice <span className="date">(Grand Union, 2004)</span>
            </li>
            <li>
              Agent Provocateur <span className="date">(Suburb, 2003)</span>
            </li>
            <li>
              Regus <span className="date">(Precedent, 2003)</span>
            </li>
            <li>
              ITV <span className="date">(Suburb, 2002)</span>
            </li>
            <li>
              Polaroid <span className="date">(Netcel, 2001)</span>
            </li>
          </ul>
        </div>
      </section>
      <section>
        <div className="section employment">
          <h2>Employment</h2>
          <h3>
            Freelance technical lead and front-end developer{" "}
            <span className="date">(Oct 2007 - Present)</span>
          </h3>
          <p>
            Consulting and development work for a range of companies including
            Grand Union, Google, Dare, Five, Precedent, Harvest Digital, Lost
            Property, Mentally Friendly, Head London, Suburb, Story Worldwide,
            FMG, Leagas Delaney and Sectorlight.
          </p>
          <h3>
            Lead front-end architect at{" "}
            <a href="http://www.thegrandunion.com/">Grand Union</a>{" "}
            <span className="date">(July 2006 - Oct 2007)</span>
          </h3>
          <p>
            Technical lead for a team of front-end developers, working alongside
            creative, design and UX to build sites for Boots, COI, Digital UK,
            Coca-Cola, Sky, Energy Saving Trust, Abbey Santander, HSBC and
            npower.
          </p>
          <h3>
            Freelance web developer{" "}
            <span className="date">(August 2001 - June 2006)</span>
          </h3>
          <p>
            Freelance development work for a number of companies including Grand
            Union, Precedent, TMG, Harvest Digital, Clark McKay and Walpole,
            Heath Kane Design and Suburb.
          </p>
          <h3>
            Web developer at <a href="http://www.netcel.com/">Netcel</a>{" "}
            <span className="date">(June 1999 - July 2001)</span>
          </h3>
          <p>
            Built new websites, provided training for clients and assisted with
            configuration and maintenance of servers.
          </p>
        </div>
      </section>
      <section>
        <div className="section education">
          <h2>Education</h2>
          <h3>
            B.Sc. Computer Science and English (Dual Honours) at{" "}
            <a href="http://www.keele.ac.uk/">Keele University</a>{" "}
            <span className="date">(Sept 1995 - June 1998)</span>
          </h3>
          <p>Degree Class 2:1</p>
        </div>
      </section>
      <section>
        <div className="section outside">
          <h2>Outside</h2>
          <p>
            In my spare time I help run a small{" "}
            <a href="http://unchartedaudio.com/">record label</a>.
          </p>
          <p>
            I also enjoy taking <a href="http://flic.kr/ohskylab">bad photos</a>
            , baking good cakes,{" "}
            <a href="http://www.last.fm/user/ohskylab">music</a> and{" "}
            <a href="http://www.alltrails.com/members/paul-user/home">hiking</a>
            .
          </p>
        </div>
      </section>
    </main>
  </Layout>
);
