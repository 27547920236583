import React from "react";
import Helmet from "react-helmet";
import "./layout.css";

function Layout(props, { children }) {
  return (
    <div className="frame">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title}</title>
        <meta content="{props.title}" name="description" />
        <meta
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
          name="viewport"
        />
        <link color="#d8423d" href="/safari-pinned-tab.svg" rel="mask-icon" />
      </Helmet>
      {props.children}
    </div>
  );
}

export default Layout;
